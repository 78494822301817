import { isMoblieDevice } from '../utils/utils'
/**
 * 安卓下载页面
 */
export function toAndroidPage() {
  window.open('https://go.onelink.me/app/apphiwebforandroid', '_blank')
}

/**
 * ios下载页面
 */
export function toIosPage() {
  window.open(
    'https://itunes.apple.com/us/app/apphi-scheduler-for-instagram/id1251406049?mt=8',
    '_blank'
  )
}

/**
 * web登录页面
 */
export function toWebPage(type = 'login') {
  if (type === 'login') {
    if (isMoblieDevice()) {
      window.open(`${process.env.VUE_APP_MOBILE_URL}/#/?type=login`, '_blank')
    } else {
      window.open(`${process.env.VUE_APP_DESKTOP_URL}`, '_blank')
    }
  } else {
    if (isMoblieDevice()) {
      window.open(`${process.env.VUE_APP_MOBILE_URL}/#/?type=create`, '_blank')
    } else {
      window.open(`${process.env.VUE_APP_DESKTOP_URL}/#/?type=create`, '_blank')
    }
  }
}
/**
 * 去terms 页面
 */
export function toTermsPage() {
  window.open(`/tos.html`, '_blank')
}
/**
 * 去privacy policy 页面
 */
export function toPrivacyPage() {
  window.open(`/privacy-policy.html`, '_blank')
}
export function toInsPage() {
  window.open(`https://www.instagram.com/apphipost/`, '_blank')
}
export function toYoutubePage() {
  window.open(
    `https://www.youtube.com/channel/UC8RFp554o4otAp-GWa6pl6Q`,
    '_blank'
  )
}
export function toFacebookPage() {
  window.open(`https://www.facebook.com/apphipost/`, '_blank')
}
export function toBlogPage() {
  window.open(`https://blog.apphi.com/`, '_blank')
}
export function toFAQPage() {
  window.open(`https://faq.apphi.com/`, '_blank')
}
