import LoadingComponent from '../components/Loading'
let $loadingVm
export function createLoadingVM(Vue) {
    const Loading = Vue.extend(LoadingComponent)
    const $loadingVm = new Loading({
        el: document.createElement('div')
    })
    document.body.appendChild($loadingVm.$el)
    return $loadingVm
}
/**
 * 加载样式弹出框
 */
export const LoadingPlugin = {
    install(Vue) {
        if (!$loadingVm) {
            $loadingVm = createLoadingVM(Vue)
        }

        const loading = {
            show() {
                $loadingVm.showModal = true
            },
            hide() {
                $loadingVm.showModal = false
            }
        }
        Vue.mixin({
            created() {
                this.$loading = loading
            }
        })
    }
}
