<template>
    <div class="home-page">
        <header-component class="header-fixed"></header-component>
        <div class="header-container">
            <div class="container">
                <div class="col-md-12">
                    <div class="header-title">
                        <div>World’s best marketing platform for Instagram, Facebook, Twitter and Linkedin</div>
                        <!-- <text-carousel-component class="carousel" :list="carouselTextList"></text-carousel-component> -->
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="download-container">
                        <div class="download-btn ios" @click="iosPage"></div>
                        <div class="download-btn android" @click="androidPage"></div>
                        <div class="download-btn web" @click="webPage"></div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="tip-message-1">Free forever. Set up in minutes. No credit card required.</div>
                </div>
                <div class="col-md-12">
                    <div class="tip-message-2">Trusted by publishers and businesses</div>
                </div>
            </div>
            <video ref="video" loop muted id="bgvid-hero" poster="../../public/video/video-hero/poster.jpg?v=161112">
                <source src="../../public/video/video-hero/vb.mp4?v=20161110" type="video/mp4">
                <source src="../../public/video/video-hero/vb.webm" type="video/webm">
            </video>
            <div class="log-list-container">
                <img src="../../public/logos/logo_stregis@3x.png"/>
                <img src="../../public/logos/logo_sheraton@3x.png"/>
                <img src="../../public/logos/logo_yelp@3x.png"/>
                <img src="../../public/logos/logo_home@3x.png"/>
                <img src="../../public/logos/logo_davidoff@3x.png"/>
                <img src="../../public/logos/logo_levis@3x.png"/>
                <img src="../../public/logos/logo_clips@3x.png"/>
                <img src="../../public/logos/logo_pubity@3x.png"/>
            </div>
        </div>
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="tip-container">
                        <div>{{tipMessages}}</div>
                        <div style="margin-top: 3px">Schedule and auto post to Instagram, Facebook, Twitter and LinkedIn.</div>
                    </div>
                </div>
            </div>
        </div>
         <div class="des-item-container" v-for="item in desList" :key="item.key">
            <div class="container">
                <div class="row">
                    <div class="col-md-7  des-img-container">
                        <img :src="item.imgUrl" alt="feature"/>
                    </div>
                    <div class="col-md-5 des-text-container">
                        <div class="des-title">{{item.title}}</div>
                        <div class="des-text des-tip">{{item.tip}}</div>
                        <div class="des-text">
                            <div class="des-detail-text-container" :key="text" v-for="text in item.desDetail">
                                <div class="left-icon">•</div>
                                <div class="right-text">{{text}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
        <bottom-info-component></bottom-info-component>
        <div class="bottom-mask"></div>
    </div>
</template>
<style lang="less" scoped>
    @import "../assets/style/theme.less";

    .home-page {
        overflow: hidden;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        .bottom-mask{
            background: #f9f9f9;
            flex: 1;
        }
        .carousel {
            margin-top: 10px;
            @media (max-width: 576px) {
                margin-top: 6px;
            }
        }
        .header-container {
            overflow: hidden;
            position: relative;
            padding: 204px 0 51px 0;
            background-color: rgba(0, 0, 0, .35);
            @media (max-width: 576px) {
                padding: 140px 0 51px 0;
            }

            .header-title {
                @media (max-width: 576px) {
                    font-size: 37px;
                    line-height: 1.3;
                }
                font-size: 40px;
                color: #ffffff;
                text-align: center;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .ins {
                    text-decoration: underline;
                    white-space: nowrap;
                }
            }

            .download-container {
                margin-top: 38px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                @media (min-width: 576px) {
                    & {
                        flex-direction: row;
                    }
                }

                .download-btn {
                    width: 180px;
                    height: 54px;
                    background-size: contain;
                    margin: 16px 12px;
                    background-repeat: no-repeat;
                    background-position: center;
                    cursor: pointer;
                    @media (max-width: 576px) {
                        margin: 10px 12px;
                    }

                    &:hover {
                        opacity: .7;
                    }

                    &.ios {
                        background-image: url('../../public/home/btn_ios_small@3x.png');
                    }

                    &.android {
                        background-image: url('../../public/home/btn_android_small@3x.png');
                    }

                    &.web {
                        background-image: url('../../public/home/btn_webdesktop_small@3x.png');
                    }
                }
            }

            .tip-message-1, .tip-message-2 {
                font-size: 18px;
                color: #ffffff;
                text-align: center;
                line-height: 1.2;
            }

            @media (max-width: 576px) {
                .tip-message-1 {
                    font-size: 16px;
                }
            }

            .tip-message-2 {
                margin-top: 62px;
                font-weight: 500;
            }

            .log-list-container {
                text-align: center;
                margin-top: 20px;
zoom: 0.7;
                img {
                    margin: 0 15px 10px 15px;
                    height: 54px;
                }
            }

            #bgvid-hero {
                position: absolute;
                top: 50%;
                left: 50%;
                width: auto;
                height: auto;
                min-width: 100%;
                min-height: 100%;
                transform: translate(-50%, -50%);
                z-index: -1;
                object-fit: cover;
            }
        }

        .header-fixed {
            position: fixed;
            width: 100%;
            top: 0;
            left: 0;
            z-index: 10;
            display: flex;
            align-content: center;

        }

        .tip-container {
            border-radius: 5px;
            text-align: center;
            font-weight: normal;
            font-stretch: normal;
            color: #323232;
            background-color: #f9f9f9;
            padding: 16px 48px;
            line-height: 30px;
            font-size: 22px;
            white-space: pre-line;
            margin-top: 53px;
            @media (max-width: 576px) {
                font-size: 18px;
                padding: 16px 15px;
                line-height: 26px;
                letter-spacing: 0.3px;
            }
        }

        .des-item-container {
            padding: 67px 0;
            @media (max-width: 576px) {
                padding: 67px 20px;
                letter-spacing: 0.3px;
            }

            &:nth-child(2n) {
                .row {
                    flex-direction: row-reverse;

                    .des-img-container img {
                        left: 65px;
                    }
                }
            }

            &:nth-child(2n-1) {
                // background: #f9f9f9;

                .des-img-container img {
                    right: 65px;
                }

            }

            @media (min-width: 576px) {
                .row {
                    flex-direction: initial;
                }

            }
            @media (max-width: 767px) {
                .des-img-container img {
                    left: auto !important;
                    right: auto !important;
                }
            }

            .des-text-container {
                display: flex;
                justify-content: center;
                flex-direction: column;

                .des-title {
                    white-space: pre-line;
                    font-size: 36px;
                    color: #323232;
                    line-height: 43px;
                    font-weight: 500;
                    @media (max-width: 767px) {
                        margin-top: 20px;
                    }
                }

                .des-detail-text-container {
                    display: flex;
                    white-space: pre-line;
                    font-size: 16px;
                    line-height: 22px;
                    color: #323232;
                    width: 100%;
                    margin-bottom: 10px;
                    @media (max-width: 767px) {
                        margin-bottom: 8px;
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }

                    .left-icon {
                        width: 20px;
                    }

                    .right-text {
                        flex: 1;
                    }
                }

                .des-text {
                    white-space: pre-line;
                    font-size: 16px;
                    line-height: 24px;
                    color: #323232;
                    margin-top: 20px;

                    &.des-tip {
                        font-size: 18px;
                        font-weight: normal;
                        margin-top: 20px;
                        line-height: 26px;
                    }
                }
            }

            .des-img-container {
                align-items: center;
                display: flex;
                justify-content: center;
                position: relative;
                min-height: 554px;
                @media (max-width: 767px) {
                    min-height: inherit;
                }

                img {
                    @media (max-width: 767px) {
                        width: 100% ;
                        height: auto ;
                        left: auto ;
                        right: auto ;
                        position: inherit ;
                    }
                    height: 554px;
                    margin: 0 auto;
                    position: absolute;
                    right: 0;
                }
            }
        }
    }
</style>
<script>
    import img1 from '../../public/home/wesite_pic_1@3x.png'
    import img2 from '../../public/home/website_pic_2@3x.png'
    // import img3 from '../../public/home/website_pic_3@3x.png'
    // import img4 from '../../public/home/website_pic_4@3x.png'
    // import img5 from '../../public/home/website_pic_5@3x.png'
    import BottomInfoComponent from "../components/BottomInfo";
    import HeaderComponent from "../components/Header";
    import {toAndroidPage, toIosPage, toWebPage} from "../services/common";
    import {getDevice} from "../utils/utils";

    export default {
        name: 'HomePage',
        components: { HeaderComponent, BottomInfoComponent},
        data() {
            return {
                carouselTextList: [
                    'Story, Photos, Videos',
                    'First Comment',
                    'Bulk Schedule',
                    'Repeat Schedule',
                    'Suggested Hashtags',
                    'Advanced Analytics',
                    'Feed Design',
                    'Story URL',
                    'Tag Product',
                    'Tag People, Location'
                ],
                tipMessages: 'Managing Instagram account requires a lot of effort especially if you are an influencer, agency or brand. Apphi helps you schedule everything, and automatically post them on your Instagram.',
                desList: [
                    {
                        id: 1,
                        imgUrl: img1,
                        title: 'Schedule And Auto Post Photos, Videos on Instagram',
                        tip: ' Plan all your posts ahead. Organize and import your media from anywhere, any devices. Spend less time managing your Instagram and more time growing your business. ',
                        desDetail: [
                            'Automatically Post Your Feed',
                            'Visually plan with Drag And Drop',
                            'Add People, Location, Hashtags To Auto Post',
                            'Set repeat time to post',
                            'Bulk schedule multiple photos and videos at once',
                            'Add first comment to post',
                            'Best time to post'
                        ]
                    },
                    {
                        id: 2,
                        imgUrl: img2,
                        title: 'Manage Unlimited Social Accounts',
                        tip: 'Keep all of your Instagram in one place and access on any devices.',
                        desDetail: [
                            'No more 5 accounts limitation from Instagram. Add and manage as many as you want',
                            'Schedule and auto post on multiple Instagram at once',
                            'Import media from mobile, desktop, Google Drive, or Dropbox',
                            'Add members to help manage without giving away Instagram password'
                        ]
                    },
                    // {
                    //     id: 3,
                    //     imgUrl: img3,
                    //     title: 'Search And Repost Instagram Content',
                    //     tip: 'Get content inspiration by searching hashtags, people and location. Schedule and repost directly to your Instagram.',
                    //     desDetail: [
                    //         'Search by hashtag, people, and location',
                    //         'Track competitors’ content and engagement',
                    //         'View and filter results by top likes, comments, views',
                    //         'Schedule and repost directly',
                    //         'Save content for future repost'
                    //     ],
                    // },
                    // {
                    //     id: 4,
                    //     imgUrl: img4,
                    //     title: 'Boost Sales From Your Instagram',
                    //     tip: 'Schedule and auto post your media with “Tag Product”, “Story URL”. Convert your followers to your customers.',
                    //     desDetail: [
                    //         'Tag product on your post',
                    //         'Post with Story URL'
                    //     ]
                    // },
                    // {
                    //     id: 5,
                    //     imgUrl: img5,
                    //     title: 'Advanced Analytics You Won’t Find Anywhere Else',
                    //     tip: 'Numbers are the best teachers. Learn how to improve with Analytics!',
                    //     desDetail: [
                    //         'Find out why you get more followers from one post than another',
                    //         'Find out how many followers, likes, comments per hour, per day, per week',
                    //         'Complete engagement analytics.'
                    //     ]
                    // }
                ]
            }
        },
        methods: {
            /**
             * 安卓下载页面
             */
            androidPage() {
                toAndroidPage()
            },
            /**
             * ios下载页面
             */
            iosPage() {
                toIosPage()
            },
            /**
             * web登录页面
             */
            webPage() {
                toWebPage()
            }
        },
        mounted() {
            if (getDevice() === 'web') {
                this.$refs.video.setAttribute('autoplay', true);
            }
        }
    }
</script>
