import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import { LoadingPlugin } from "./plugins/Loading";

Vue.use(BootstrapVue)
Vue.use(LoadingPlugin)
Vue.config.productionTip = false
Vue.prototype.$bus = Vue.prototype.$bus || new Vue() // 注册一个全局的总线组件
// eslint-disable-next-line no-console
console.log('version 2019-6-26')
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
