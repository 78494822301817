<template>
  <div
    class="header-component"
    style="z-index:5"
    :class="{transparency:transparency,home:type === 'home','not-top':!top}"
  >
    <div class="header-top-container">
      <div class="container">
        <div class="row web-container">
          <div class="col-md-12 header-container">
            <div class="apphi-logo" @click="homePage"></div>
            <div class="web-menu-list">
              <router-link :to="{name: 'PricePage'}">
                <div class="menu-item" :class="{active:$route.name === 'PricePage'}">Pricing</div>
              </router-link>
              <div class="menu-item" @click="contactUsClick">Contact Us</div>
              <div class="menu-item" @click="blogPage">Blog</div>
              <div class="menu-item" @click="loginPage">Login</div>
              <div class="menu-item" @click="registerPage">Create Account</div>
            </div>
          </div>
        </div>
        <div class="row app-container">
          <div class="col-md-12 header-container">
            <div class="apphi-logo" @click="homePage"></div>
            <div @click="switchClick" class="more-icon"></div>
          </div>
        </div>
      </div>
      <div class="app-menu-list" v-show="open">
        <router-link :to="{name: 'PricePage'}">
          <div class="menu-item" :class="{active:$route.name === 'PricePage'}">Pricing</div>
        </router-link>
        <div class="menu-item" @click="contactUsClick">Contact Us</div>
        <div class="menu-item" @click="blogPage">Blog</div>
        <div class="menu-item" @click="loginPage">Login</div>
        <div class="menu-item" @click="registerPage">Create Account</div>
      </div>
    </div>
  </div>
</template>

<script>
import { toBlogPage, toWebPage } from "../services/common";
import { isMoblieDevice } from "../utils/utils";
import { setTimeout } from "timers";

export default {
  name: "HeaderComponent",
  props: {
    type: {
      type: String,
      default: "home"
    }
  },
  computed: {
    /**
     * 之后当home页面的时候才在顶端显示透明样式
     * @returns {boolean}
     */
    transparency() {
      return this.type === "home" && this.top;
    }
  },
  data() {
    return {
      open: false, // 是否展开菜单
      top: true // 页面是否处于顶端
    };
  },
  methods: {
    contactUsClick() {
      const isMoblie = isMoblieDevice();
      if (isMoblie) {
        this.$router.push({ name: "ContactPage" });
      } else {
        this.$bus.$emit("showContactUs");
      }
    },
    switchClick() {
      this.open = !this.open;
    },
    homePage() {
      this.$router.push({ name: "HomePage" });
    },
    blogPage() {
      toBlogPage();
    },
    loginPage() {
      toWebPage();
    },
    registerPage() {
      toWebPage("register");
    }
  },
  async mounted() {
    setTimeout(() => {
      window.addEventListener("scroll", () => {
        const top =
          document.documentElement.scrollTop ||
          window.pageYOffset ||
          document.body.scrollTop; // 滚动条的高度，即滚动条的当前位置到div顶部的距离
        this.top = top < 20;
      });
    }, 2000);
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
@import "../assets/style/theme.less";
.mask-container {
  background: red;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
}
.header-component {
  box-shadow: 0 1px 12px 0 rgba(51, 51, 51, 0.23);
  padding: 36px 0 24px 0;
  background-color: rgba(255, 255, 255, 0.97);
  width: 100%;
  /*app模式强制北京色和高度*/
  @media (max-width: 800px) {
    & {
      background-color: white !important;
      padding: 10px 0 !important;
      .apphi-logo {
        background-image: url("../../public/home/Apphi_red@3x.png") !important;
      }
    }
  }

  &.home {
    padding: 10px 0;
  }

  &.transparency {
    background-color: transparent;
    box-shadow: inherit;

    .header-top-container .web-container .web-menu-list .menu-item {
      color: white;
    }
    /*特殊处理透明header下的logo是白色的*/
    .header-top-container .apphi-logo {
      background-image: url("../../public/home/Apphi@3x.png");
    }
  }

  .header-top-container {
    width: 100%;
    height: 60px;

    .header-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 60px;
      .more-icon {
        background-position: center;
        background-repeat: no-repeat;
        background-image: url("../../public/home/gengduo.svg");
        width: 43px;
        height: 33px;
        background-size: 20px 20px;
        cursor: pointer;
        border: 1px solid #ddd;
        border-radius: 4px;
        color: #ddd;
        &:hover {
          opacity: 0.7;
        }
      }
    }

    .apphi-logo {
      height: 41px;
      width: 86px;
      background-size: contain;
      background-image: url("../../public/home/Apphi_red@3x.png");
      cursor: pointer;
      &:hover {
        opacity: 0.7;
      }
    }

    .app-container {
      @media (min-width: 800px) {
        display: none;
      }
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 100%;
    }

    .app-menu-list {
      @media (min-width: 800px) {
        display: none;
      }
      border-top: 1px solid #e7e7e7;
      box-shadow: 0 3px 3px 0 rgba(51, 51, 51, 0.23);
      padding: 10px 0;
      background: white;
      position: relative;
      top: 5px;
      width: 100vw;

      a {
        color: transparent;
        text-decoration: none;
      }

      .menu-item {
        padding: 10px 0;
        line-height: 20px;
        color: #4c4c4c;
        text-align: center;
        cursor: pointer;
        &:hover {
          color: @theme-color;
        }
      }
    }

    .web-container {
      @media (max-width: 800px) {
        display: none;
      }

      .web-menu-list {
        display: flex;
        flex: 1;
        justify-content: flex-end;

        a {
          color: transparent;
          text-decoration: none;
        }

        .menu-item {
          margin: 0 15px;
          cursor: pointer;
          font-size: 17px;
          color: black;
          &.active {
            color: @theme-color;
          }
          &:hover {
            color: @theme-color;
          }
        }
      }
    }
  }
}
</style>
