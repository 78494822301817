<template>
    <div class="bottom-container">
        <div class="container row">
            <div class="link-list col-md-6">
                <div class="link-title">APPHI</div>
                <div class="link-item">About</div>
                <!-- <div class="link-item" @click="pricePage">Pricing</div> -->
                <div class="link-item" @click="iosPage">Apphi for iOS</div>
                <div class="link-item" @click="androidPage">Apphi for Android</div>
                <div class="link-item" @click="webPage">Apphi for Desktop(Beta)</div>
                <div class="link-item" @click="FAQPage">FAQ</div>
                <div class="link-tip">Apphi is based in Seattle❤️</div>
                <div class="link-thrid-list">
                    <div class="thrid-item"></div>
                </div>
                <div class="link-thrid-list">
                    <div class="thrid-item ins" @click="insPage"></div>
                    <div class="thrid-item youtube" @click="youtubePage"></div>
                    <div class="thrid-item fb" @click="facebookPage"></div>
                </div>
            </div>
            <div class="subscribe-container col-md-6">
                <div class="subscribe-title">SUBSCRIBE TO OUR NEWSLETTER</div>
                <div class="subscribe-tip">
                    The latest Apphi news, articles, and resources, sent straight to your inbox every month.
                </div>
                <form action="https://apphi.us2.list-manage.com/subscribe/post?u=a0acd9938760c4d3b8f8ce280&id=71ddc67a42"
                      method="post" target="_blank">
                    <div class="subscribe-component">
                        <div class="left-container">
                            <input name="EMAIL" type="email" required class="email" placeholder="Your email address"/>
                        </div>
                        <button class="right-btn">Subscribe</button>
                    </div>
                </form>
                <div class="copyright"><span>Copyright ©2020 Apphi - <span class="click-able"
                                                                           @click="termsPage">Terms of
                    Use</span> - <span @click="privacyPage" class="click-able">Privacy Policy</span></span></div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        toAndroidPage,
        toIosPage,
        toWebPage,
        toPrivacyPage,
        toTermsPage,
        toFacebookPage,
        toInsPage,
        toYoutubePage,
        toFAQPage
    } from "../services/common";

    export default {
        name: 'BottomInfoComponent',
        methods: {
            pricePage() {
                this.$router.push({name: 'PricePage'})
            },
            iosPage() {
                toIosPage()
            },
            androidPage() {
                toAndroidPage()
            },
            webPage() {
                toWebPage()
            },
            termsPage() {
                toTermsPage()
            },
            privacyPage() {
                toPrivacyPage()
            },
            insPage() {
                toInsPage()
            },
            youtubePage() {
                toYoutubePage()
            },
            facebookPage() {
                toFacebookPage()
            },
            FAQPage() {
                toFAQPage()
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
    .bottom-container {
        padding: 30px 0 83px 0 !important;
        display: flex;
        justify-content: center;
        background: #f9f9f9;
        color: #5a5a5a;
        @media (min-width: 769px) {
            & {
                .subscribe-container {
                    margin-top: 0 !important;
                }
            }
        }

        .link-list {
            font-size: 16px;
            position: relative;
            .link-tip {
                color: #777;
                margin-top: 25px;
            }
            .link-thrid-list {
                display: flex;
                position: absolute;
                bottom: -38px;
                .thrid-item {
                    width: 20px;
                    height: 20px;
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center;
                    margin-right: 20px;
                    cursor: pointer;
                    :hover {
                        opacity: .7;
                    }
                    &.fb {
                        background-image: url('../../public/home/fb.png');
                    }
                    &.youtube {
                        background-image: url('../../public/home/youtube.png');
                    }
                    &.ins {
                        background-image: url('../../public/home/ins.png');
                    }
                }
            }
            .link-item {
                color: #777;
                margin-bottom: 8px;
                cursor: pointer;

                &:last-child {
                    margin-bottom: 0;
                }

                &:hover {
                    opacity: .7;
                }
            }

            .link-title {
                margin-bottom: 16px;
                font-weight: 500;
            }
        }

        .subscribe-container {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-top: 80px;

            .subscribe-title {
                font-weight: 500;
            }

            .subscribe-tip {
                margin-top: 24px;
                @media (max-width: 767px) {
                    line-height: 20px;
                }
            }

            .subscribe-component {
                width: 100%;
                height: 48px;
                display: flex;
                margin-top: 14px;
                .left-container {
                    border-radius: 5px 0 0 5px;
                    border: 1px solid #e2e2e2;
                    border-right-width: 0;
                    flex: 1;
                    background: #f0f0f0;
                    outline: none;
                    input {
                        padding: 16px 20px;
                        width: 100%;
                        height: 100%;
                        border: 0;
                        background: transparent;
                        outline: none;
                        &::placeholder {
                            color: #8c8c8c;
                        }
                    }

                }

                .right-btn {
                    width: 129px;
                    border-radius: 0 5px 5px 0;
                    background: #e72955;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: white;
                    cursor: pointer;
                    outline: none;
                    border: 0;
                    &:hover {
                        opacity: .7;
                    }

                }
            }

            .copyright {
                flex: 1;
                display: flex;
                align-items: flex-end;
                color: #5a5a5a;
                font-size: 14px;
                margin-top: 10px;
                .click-able {
                    cursor: pointer;
                    white-space: nowrap;
                    &:hover {
                        opacity: .7;
                    }
                }
            }
        }
    }
</style>
