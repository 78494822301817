<template>
    <div id="app">
        <router-view/>
        <div v-if="showContactUs" @click.stop="close" class="modal-container">
            <div class="main-container">
                <div class="close-btn" @click="close">×</div>
                <div class="contact-container">
                    <iframe ref="iframe" height="641" allowTransparency="true" frameborder="0" scrolling="no"
                            style="width:100%;border:none" src="https://mapiz.wufoo.com/embed/qj2yv7j1r10i0w/"><a
                            href="https://mapiz.wufoo.com/forms/qj2yv7j1r10i0w/">Fill out my Wufoo form!</a></iframe>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

    export default {
        name: 'AppPage',
        data() {
            return {
                showContactUs: false
            }
        },
        methods: {
            close() {
                this.showContactUs = false
            }
        },
        watch: {
            showContactUs(val) {
                const _this = this
                if (val) {
                    _this.$loading.show()
                    this.$nextTick(()=>{
                        const iframe = this.$refs.iframe
                        if (iframe.attachEvent) {
                            iframe.attachEvent("onload", function () {
                                //iframe加载完成后你需要进行的操作
                                _this.$loading.hide()
                            });
                        } else {
                            iframe.onload = function () {
                                //iframe加载完成后你需要进行的操作
                                _this.$loading.hide()
                            };
                        }
                    })
                }
            }
        },
        created() {
            this.$bus.$on('showContactUs', () => {
                this.showContactUs = true
            })
        },
    }
</script>
<style lang="less">
    #app {
        font-family: "SF Pro Text", "SF Pro Icons", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        letter-spacing: 0.5px;
    }

    a {
        color: transparent;
        text-decoration: none;
    }

    .modal-container {
        position: fixed;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
        background: rgba(0, 0, 0, .6);
        z-index: 100;
        display: flex;
        align-items: center;
        justify-content: center;
        .main-container {
            position: relative;
            .close-btn {
                position: absolute;
                right: 0;
                top: -40px;
                color: white;
                font-size: 30px;
                font-weight: 800;
                cursor: pointer;
                &:hover {
                    opacity: .7;
                }
            }
            .contact-container {
                width: 700px;
                max-width: 80vw;
                background: white;
                padding: 20px;
                max-height: 80vh;
                overflow: scroll;
            }
        }
    }
</style>
